import React from 'react';

export const Footer: React.FC = () => {
  return (
    <footer>
      <div className="container pb-5">
        <div className="row justify-content-center">
          <div className="col-12 py-4 py-md-5 text-center">
            <a href="https://hair-genie-magazine.infigate.net/" target="_blank">
              <img
                src={`${process.env.PUBLIC_URL}` + '/img/gallery/column.png'}
                className="pt-5 img-fluid"
                alt="hair-genie-magazine"
              />
            </a>
          </div>
          <div className="col-4 py-4 py-md-5 ">
            <h2 className="mb-5">Media</h2>
          </div>

          <div className="col-4 py-4 py-md-5 text-center">
            <a href="https://ledge.ai/articles/conoha-3" target="_blank">
              <img
                src={`${process.env.PUBLIC_URL}` + '/img/gallery/ledge.png'}
                className="img-fluid"
                alt="hair-genie-ledge"
              />
            </a>
          </div>
          <div className="col-4 py-4 py-md-5 text-center">
            <a
              href="https://ledge.ai/categories/coverstory/conoha-series"
              target="_blank"
            >
              <img
                src={`${process.env.PUBLIC_URL}` + '/img/gallery/conoha.png'}
                className="img-fluid"
                alt="hair-genie-conoha"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-dark">
        <div className="col-12 py-4 py-md-5 text-center">
          <a href="https://infigate.net" target="_blank">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/infigate.svg'}
              className="pb-5 img-fluid"
              alt="Infigate Logo"
            />
          </a>
          <div className="text-center">
            <a href="https://twitter.com/haya_Infigate" target="_blank">
              <img
                src={`${process.env.PUBLIC_URL}` + '/img/icon/twitter.svg'}
                className="px-2"
              />
            </a>
            <a
              href="https://www.instagram.com/hair_genie_infigate/"
              target="_blank"
            >
              <img
                src={`${process.env.PUBLIC_URL}` + '/img/icon/instagram.svg'}
                className="px-2"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
