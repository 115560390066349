import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PageLink } from 'lib/Constants';
import * as pages from 'pages/Index';
const App: React.FC = () => {
  return (
    <Router basename="/">
      <Routes location={location} key={location.pathname}>
        <Route path={PageLink.En} element={<pages.En />} />
        <Route path={PageLink.Ja} element={<pages.Ja />} />
        <Route path={PageLink.Kor} element={<pages.Kor />} />
        <Route path={PageLink.Chn} element={<pages.Chn />} />
      </Routes>
    </Router>
  );
};

export default App;
