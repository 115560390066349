import React from 'react';
import styled from 'styled-components';

const Header = styled.header`
  color: #fff;
  top: 0;
`;

interface Props {
  tab?: string;
}

export const Sidebar: React.FC<Props> = (props: Props) => {
  const { tab } = props;

  return (
    <Header className="container-fluid position-fixed py-2 py-md-5">
      <div className="row">
        <div className="col-12 col-md-6 order-1 order-md-0">
          <ul className="d-flex list-inline mb-0 horizon">
            <a href="/">
              <li className={`px-4 ${tab === 'en' && 'active'}`}>English</li>
            </a>
            <a href="/ja">
              <li className={`px-4 ${tab === 'ja' && 'active'}`}>日本語</li>
            </a>
            <a href="/chn">
              <li className={`px-4 ${tab === 'chn' && 'active'}`}>中国語</li>
            </a>
            <a href="/kor">
              <li className={`px-4 ${tab === 'kor' && 'active'}`}>한국어</li>
            </a>
          </ul>
        </div>
        <div className="col-12 col-md-6 order-0 order-md-1 mb-3 mb-md-0">
          <ul className="d-flex list-inline justify-content-end mb-0">
            <li className="px-2">
              <a href="https://twitter.com/haya_Infigate" target="_blank">
                <img
                  src={`${process.env.PUBLIC_URL}` + '/img/icon/twitter.svg'}
                  className="px-2"
                />
              </a>
            </li>
            <li className="px-2">
              <a
                href="https://www.instagram.com/hair_genie_infigate/"
                target="_blank"
              >
                <img
                  src={`${process.env.PUBLIC_URL}` + '/img/icon/instagram.svg'}
                  className="px-2"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Header>
  );
};

export default Sidebar;
