import MainLayout from 'layouts/MainLayout';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Top: React.FC = () => {
  const navigate = useNavigate();

  return (
    <MainLayout tab="chn">
      <div className="container-fluid main-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 py-5 title">
              <p>发型AI模拟App</p>
              <div className="col-12 py-5 title d-flex align-items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon/HairGenieIcon.png`}
                  className="d-block title-icon"
                  alt="HairGenie Icon"
                />
                <h1 className="display-1 display ms-3 ms-md-5">
                  AI Hair Genie
                </h1>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 pt-5 pb-md-5 mv">
              <p className="focus mb-4 mb-md-5">赶快来体验一下!</p>
              <p>
                您将体验到极其自然的发型模拟。
                <br />
                典型到奇葩，适应各种不同发型风格。
                <br />
                只需上传照片，就可以在虚拟环境中尽情的尝试各种各样的发型，
                <br />
                为您带来前所未有的发型体验。
              </p>
            </div>
            <div className="col-6 col-md-12 py-3 py-md-5 app-icons">
              <a
                href="https://apps.apple.com/jp/app/aihairgenie/id6468377644"
                target="_blank"
              >
                <img
                  src={`${process.env.PUBLIC_URL}` + '/img/icon/ios.png'}
                  className="img-fluid d-block"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.aihairgenie&pli=1"
                target="_blank"
              >
                <img
                  src={
                    `${process.env.PUBLIC_URL}` + '/img/icon/google-play.png'
                  }
                  className="img-fluid d-block"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid release-note">
        <div className="row justify-content-center">
          <div className="col-12 py-5 d-flex">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/coming.svg'}
              className="d-block mx-auto py-5 img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row justify-content-center">
          <div className="col-12 d-flex px-0">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/gallery.png'}
              className="d-block mx-auto img-fluid"
            />
          </div>
        </div>
      </div>
      <article className="container-fluid g-0">
        <div className="row justify-content-between align-items-center g-0">
          <div className="col-12 col-md-6 p-3 p-md-5">
            <h2 className="mb-5">
              <span>#1</span>拍照/选择照片
            </h2>
            <p>通过相机功能现拍一张，或者从相册里选择一张照片。</p>
          </div>
          <div className="col-12 col-md-6 py-3 py-md-5 d-flex justify-content-end">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/case1.png'}
              className="d-block py-3 py-md-5 img-fluid"
            />
          </div>
        </div>
        <div className="row justify-content-between align-items-center g-0">
          <div className="col-12 col-md-6 p-3 p-md-5 order-md-1">
            <h2 className="mb-5">
              <span>#2</span>选择更改范围
            </h2>
            <p>通过App内部小工具选择想要重新生成的范围。</p>
          </div>
          <div className="col-12 col-md-6 py-3 py-md-5 d-flex justify-content-start order-md-0">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/case2.png'}
              className="d-block py-3 py-md-5 img-fluid"
            />
          </div>
        </div>
        <div className="row justify-content-between align-items-center g-0">
          <div className="col-12 col-md-6 p-3 p-md-5">
            <h2 className="mb-5">
              <span>#3</span>选择样式
            </h2>
            <p>
              输入您想要生成的发型。AI Hair Genie提供两种输入方式。
              <br />
              其一，直接输入文字来描述发型。
              <br />
              其二，从App推荐的发型一览中选择一个您喜欢的发型。
            </p>
          </div>
          <div className="col-12 col-md-6 py-3 py-md-5 d-flex justify-content-end">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/case3.png'}
              className="d-block py-3 py-md-5 img-fluid"
            />
          </div>
        </div>
        <div className="row justify-content-between align-items-center g-0">
          <div className="col-12 col-md-6 p-3 p-md-5 order-md-1">
            <h2 className="mb-5">
              <span>#4</span>点击生成
            </h2>
          </div>
          <div className="col-12 col-md-6 py-3 py-md-5 d-flex justify-content-start order-md-0">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/case4.png'}
              className="d-block py-3 py-md-5 img-fluid"
            />
          </div>
        </div>
      </article>
      <div className="container-fluid bg-white">
        <div className="row justify-content-center">
          <h2 className="display-1 text-dark">Road map</h2>
          <div className="col-12 py-3 py-md-5 d-flex">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/roadmap-chn.svg'}
              className="d-block mx-auto py-3 py-md-5 img-fluid"
            />
          </div>
          <div className="col-12 py-3 py-md-5 d-flex">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/roadmap-v2.svg'}
              className="d-block mx-auto py-3 py-md-5 img-fluid"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
// now on
// 通过AI来生成发型
//
// Version 2
// 生成更具有潮流的发型。结果更精致。
//
// future
// 进一步对每个人的脸型进行深度学习，带给您更加现实的发型推荐

export default Top;
