import React from 'react';
import styled from 'styled-components';
import Header from 'components/Header';
import Footer from 'components/Footer';

const Container = styled.div`
  margin-top: 149px;
`;

interface MainLayoutProps {
  children: any;
  tab?: string;
  myKey?: string;
}

export const MainLayout: React.FC<MainLayoutProps> = (
  props: MainLayoutProps
) => {
  const { tab, children } = props;
  return (
    <>
      <Header tab={tab} />
      <Container>{children}</Container>
      <Footer />
    </>
  );
};

export default MainLayout;
