import MainLayout from 'layouts/MainLayout';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Top: React.FC = () => {
  const navigate = useNavigate();

  return (
    <MainLayout tab="kor">
      <div className="container-fluid main-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 py-5 title">
              <p>AI 헤어스타일 시뮬레이션 앱</p>
              <div className="col-12 py-5 title d-flex align-items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon/HairGenieIcon.png`}
                  className="d-block title-icon"
                  alt="HairGenie Icon"
                />
                <h1 className="display-1 display ms-3 ms-md-5">
                  AI Hair Genie
                </h1>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 pt-5 pb-md-5 mv">
              <p className="focus mb-4 mb-md-5">체험해보세요!</p>
              <p>
                훨씬 자연스러운 헤어스타일을 선사해드립니다.
                <br />
                클래식한 헤어스타일은 물론, 기상천회한 여려가지 헤어스타일도
                체험가능!
                <br />
                사진 한장만 업로드해주시면 다양한 헤어스타일을
                시뮬레이션해드립니다.
                <br />
                여태껏 경험해보지 못한 미력만점 헤어스타일 체험을 바로 지금!.
              </p>
            </div>
            <div className="col-6 col-md-12 py-3 py-md-5 app-icons">
              <a
                href="https://apps.apple.com/jp/app/aihairgenie/id6468377644"
                target="_blank"
              >
                <img
                  src={`${process.env.PUBLIC_URL}` + '/img/icon/ios.png'}
                  className="img-fluid d-block"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.aihairgenie&pli=1"
                target="_blank"
              >
                <img
                  src={
                    `${process.env.PUBLIC_URL}` + '/img/icon/google-play.png'
                  }
                  className="img-fluid d-block"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid release-note">
        <div className="row justify-content-center">
          <div className="col-12 py-5 d-flex">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/coming.svg'}
              className="d-block mx-auto py-5 img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row justify-content-center">
          <div className="col-12 d-flex px-0">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/gallery.png'}
              className="d-block mx-auto img-fluid"
            />
          </div>
        </div>
      </div>
      <article className="container-fluid g-0">
        <div className="row justify-content-between align-items-center g-0">
          <div className="col-12 col-md-6 p-3 p-md-5">
            <h2 className="mb-5">
              <span>#1</span>촬영/기존사진
            </h2>
            <p>카메라로 촬영하거나 사진첩에 있는 사진을 선택해주세요.</p>
          </div>
          <div className="col-12 col-md-6 py-3 py-md-5 d-flex justify-content-end">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/case1.png'}
              className="d-block py-3 py-md-5 img-fluid"
            />
          </div>
        </div>
        <div className="row justify-content-between align-items-center g-0">
          <div className="col-12 col-md-6 p-3 p-md-5 order-md-1">
            <h2 className="mb-5">
              <span>#2</span>도배영역 선택
            </h2>
            <p>
              시물레이션 할당영역을 앱에 내장되여 있는 기능으로 표시해주세요.
            </p>
          </div>
          <div className="col-12 col-md-6 py-3 py-md-5 d-flex justify-content-start order-md-0">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/case2.png'}
              className="d-block py-3 py-md-5 img-fluid"
            />
          </div>
        </div>
        <div className="row justify-content-between align-items-center g-0">
          <div className="col-12 col-md-6 p-3 p-md-5">
            <h2 className="mb-5">
              <span>#3</span>스타일을 선택
            </h2>
            <p>
              원하는 헤어스타일을 입력하세요.
              <br />
              AI Hair Genie 는 두가지 방법으로 헤어스타일 입력할수 있습니다.
              <br />
              1. 문자를 직접 입력하시는 방식. <br />
              2. 엡에서 추천해주는 헤어스타일을 선택하는방법.
              <br />
              원하는 방법으로 맘껏 헤어시뮬레이션을 즐기세요!
            </p>
          </div>
          <div className="col-12 col-md-6 py-3 py-md-5 d-flex justify-content-end">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/case3.png'}
              className="d-block py-3 py-md-5 img-fluid"
            />
          </div>
        </div>
        <div className="row justify-content-between align-items-center g-0">
          <div className="col-12 col-md-6 p-3 p-md-5 order-md-1">
            <h2 className="mb-5">
              <span>#4</span>생성 시작
            </h2>
          </div>
          <div className="col-12 col-md-6 py-3 py-md-5 d-flex justify-content-start order-md-0">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/case2.png'}
              className="d-block py-3 py-md-5 img-fluid"
            />
          </div>
        </div>
      </article>
      <div className="container-fluid bg-white">
        <div className="row justify-content-center">
          <h2 className="display-1 text-dark">Road map</h2>
          <div className="col-12 py-3 py-md-5 d-flex">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/roadmap-kor.svg'}
              className="d-block mx-auto py-3 py-md-5 img-fluid"
            />
          </div>
          <div className="col-12 py-3 py-md-5 d-flex">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/roadmap-v2.svg'}
              className="d-block mx-auto py-3 py-md-5 img-fluid"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
// now on
// AI 를 이용한 헤어스타일 생성
// version 2
// 지금 핫한 스타일, 조금더 섬세한 스타일 등등 다양한 헤어스타일이 추가됩니다.
// future
// 유저얼굴을 더 정확히 인식함으로 훌씬 훌륭한 헤어스타일 체험을 제공해 드리겠습니다.

export default Top;
