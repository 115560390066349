import MainLayout from 'layouts/MainLayout';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Top: React.FC = () => {
  const navigate = useNavigate();

  return (
    <MainLayout tab="ja">
      <div className="container-fluid main-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 py-5 title">
              <p>
                生成AIを活用した
                <br className="sp" />
                ヘアスタイル
                <br className="sp" />
                シミュレーションアプリ
              </p>
              <div className="col-12 py-5 title d-flex align-items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon/HairGenieIcon.png`}
                  className="d-block title-icon"
                  alt="HairGenie Icon"
                />
                <h1 className="display-1 display ms-3 ms-md-5">
                  AI Hair Genie
                </h1>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 pt-5 pb-md-5 mv">
              <p className="focus mb-4 mb-md-5">体験してください!</p>
              <p>
                超自然なヘアスタイルがあなたを待っています
                <br />
                ナチュラルなスタイルはもちろん、普段では決して自分で試すことはない奇抜なヘアスタイルまで
                <br />
                写真をアップロードするだけで様々なヘアスタイルをバーチャルで試すことができます。
                <br />
                これまでにないヘアスタイル体験をお届けします
              </p>
            </div>
            <div className="col-6 col-md-12 py-3 py-md-5 app-icons">
              <a
                href="https://apps.apple.com/jp/app/aihairgenie/id6468377644"
                target="_blank"
              >
                <img
                  src={`${process.env.PUBLIC_URL}` + '/img/icon/ios.png'}
                  className="img-fluid d-block"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.aihairgenie&pli=1"
                target="_blank"
              >
                <img
                  src={
                    `${process.env.PUBLIC_URL}` + '/img/icon/google-play.png'
                  }
                  className="img-fluid d-block"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid release-note">
        <div className="row justify-content-center">
          <div className="col-12 py-5 d-flex">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/coming.svg'}
              className="d-block mx-auto py-5 img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row justify-content-center">
          <div className="col-12 d-flex px-0">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/gallery.png'}
              className="d-block mx-auto img-fluid"
            />
          </div>
        </div>
      </div>
      <article className="container-fluid g-0">
        <div className="row justify-content-between align-items-center g-0">
          <div className="col-12 col-md-6 p-3 p-md-5">
            <h2 className="mb-5">
              <span>#1</span>写真を撮る
            </h2>
            <p>
              カメラから撮影、または写真ライブラリから既存の写真を選択します。
            </p>
          </div>
          <div className="col-12 col-md-6 py-3 py-md-5 d-flex justify-content-end">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/case1.png'}
              className="d-block py-3 py-md-5 img-fluid"
            />
          </div>
        </div>
        <div className="row justify-content-between align-items-center g-0">
          <div className="col-12 col-md-6 p-3 p-md-5 order-md-1">
            <h2 className="mb-5">
              <span>#2</span>範囲を指定
            </h2>
            <p>髪の毛の部分をアプリ内のツールで塗りつぶして選択します。</p>
            <p>
              塗りつぶしの範囲は少しはみ出すくらいが自然な生成のポイントです。
            </p>
          </div>
          <div className="col-12 col-md-6 py-3 py-md-5 d-flex justify-content-start order-md-0">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/case2.png'}
              className="d-block py-3 py-md-5 img-fluid"
            />
          </div>
        </div>
        <div className="row justify-content-between align-items-center g-0">
          <div className="col-12 col-md-6 p-3 p-md-5">
            <h2 className="mb-5">
              <span>#3</span>スタイルを選択
            </h2>
            <p>髪型、前髪の形、髪色などから希望のスタイルを選択します。</p>
          </div>
          <div className="col-12 col-md-6 py-3 py-md-5 d-flex justify-content-end">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/case3.png'}
              className="d-block py-3 py-md-5 img-fluid"
            />
          </div>
        </div>
        <div className="row justify-content-between align-items-center g-0">
          <div className="col-12 col-md-6 p-3 p-md-5 order-md-1">
            <h2 className="mb-5">
              <span>#4</span>生成をタップ
            </h2>
            <p>
              AIによる生成処理が行われ、髪の毛の動き、質感、ツヤ感など自然な仕上がりのシミュレーションが完成します
            </p>
          </div>
          <div className="col-12 col-md-6 py-3 py-md-5 d-flex justify-content-start order-md-0">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/case4.png'}
              className="d-block py-3 py-md-5 img-fluid"
            />
          </div>
        </div>
      </article>
      <div className="container-fluid bg-white">
        <div className="row justify-content-center">
          <h2 className="display-1 text-dark">Road map</h2>
          <div className="col-12 py-3 py-md-5 d-flex">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/roadmap-ja.svg'}
              className="d-block mx-auto py-3 py-md-5 img-fluid"
            />
          </div>
          <div className="col-12 py-3 py-md-5 d-flex">
            <img
              src={`${process.env.PUBLIC_URL}` + '/img/gallery/roadmap-v2.svg'}
              className="d-block mx-auto py-3 py-md-5 img-fluid"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Top;
